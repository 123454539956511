import AuthLayout, { AuthProps, IAlertProps, IInputError } from '@/components/layout/auth-layout'
import GuestLayout from '@/components/layout/guest-layout'
import AuthInput from '@/components/shared/auth-input'
import { useAuthContext } from '@/context/auth-context'
import { useAuth } from '@/hooks/use-auth'
import { handleError } from '@/libraries/util'
import { Typography } from '@mui/material'
import Grid from '@mui/material/Grid'
import { useRouter } from 'next/router'
import { ChangeEvent, FormEvent, MouseEvent, useState } from 'react'

function LoginPage() {
  const router = useRouter()
  const { signIn } = useAuthContext()
  const [busy, setBusy] = useState(false)
  const [alert, setAlert] = useState<IAlertProps | null>(null)
  const [usernameError, setUsernameError] = useState<IInputError | null>(null)

  const [values, setValues] = useState<AuthProps>({
    email: '',
    password: '',
    confirmPassword: '',
    showPassword: false,
  })

  const handleChange = (prop: keyof AuthProps) => (event: ChangeEvent<HTMLInputElement>) => {
    setValues({ ...values, [prop]: event.target.value })
  }

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    })
  }

  const handleMouseDownPassword = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
  }

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    try {
      setBusy(true)
      setAlert(null)
      setUsernameError(null)
      // const data = new FormData(event.currentTarget)
      //   email: data.get('email'),
      //   password: data.get('password'),
      // })
      await signIn(values.email, values.password)
      router.replace('/main/').then()
    } catch (error) {
      handleError(error, (message) => {
        // setAlert({ type: AlertType.error, message })
        setUsernameError({ message })
      })
    } finally {
      setBusy(false)
    }
  }

  return (
    <GuestLayout title={'login page'}>
      <AuthLayout
        busy={busy}
        alert={alert}
        handleSubmit={handleSubmit}
        title={'Hi, Welcome!'}
        description={'Please Sign in to continue.'}
        buttonName={'Sign In'}
      >
        <>
          <AuthInput
            required
            id={'email'}
            name={'email'}
            value={values.email}
            placeholder={'Enter your email address here'}
            type={'email'}
            title={'Email address'}
            helperText={''}
            error={usernameError}
            onChange={handleChange('email')}
            showInputAdornment={false}
            onHandleClickInputAdornment={null}
            onHandleMouseInputAdornment={null}
          />

          <Grid container direction="row" justifyContent="flex-end" alignItems="center" sx={{ mt: 1.2 }}>
            <AuthInput
              required
              id={'password'}
              name={'password'}
              value={values.password}
              placeholder={'Enter your password here'}
              type={values.showPassword ? 'text' : 'password'}
              title={'Password'}
              helperText={'Tip: Password should have at least 8 characters, 1 uppercase letter'}
              onChange={handleChange('password')}
              showInputAdornment={values.showPassword}
              onHandleClickInputAdornment={handleClickShowPassword}
              onHandleMouseInputAdornment={handleMouseDownPassword}
            />
          </Grid>

          <Grid container direction="row" justifyContent="flex-end" alignItems="center" sx={{ mt: 2 }}>
            <Typography
              component="small"
              sx={{
                fontSize: 12,
                fontWeight: 400,
                color: 'text.primary',
              }}
            >
              <a href={'/auth/forgot-password'}>Forgot password?</a>
            </Typography>
          </Grid>
        </>
      </AuthLayout>
    </GuestLayout>
  )
}

export default useAuth({
  Component: LoginPage,
  middleware: 'guest',
  redirectIfAuthenticated: '/main',
})
